import React, { Component } from "react";
import {SynUserContextProvider} from "../context/userContext";
import SynRouter from "../router/router";
import AuthService from "../services/auth.service"
import { BrowserRouter } from "react-router-dom";


class Auth extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        // showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        // showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  render() {

    return (
        <SynUserContextProvider value={this.user}>
            <BrowserRouter>
            <SynRouter />
            </BrowserRouter>
        </SynUserContextProvider>
          
    );
  }
}

export default Auth