import React from "react";
import { Routes ,Route } from 'react-router-dom';
import Loadable from 'react-loadable';

// import Login from '../pages/auth/login'
// import Report from '../pages/report'
// import AnimalInfo from "../pages/animal-info";
// import CreateCat from "../pages/create-pet";
// import Home from "../pages/home";
// import PanelInfo from "../pages/panel-info";
// import MenuPage from "../pages/menu-page";
// import MinimalLayout from "../layout/MinimalLayout";

const LoadingComponent = ({isLoading, error}) => {
  if(isLoading) {
    return <div>Loading...</div>
  }
  else if(error){
    return <div>Sorry, unable to load the page..</div>
  }
  else {
    return null
  }
}

const Login = Loadable({
  loader: () => import('../pages/auth/login'),
  loading: LoadingComponent
});
const AnimalInfo = Loadable({
  loader: () => import('../pages/animal-info'),
  loading: LoadingComponent
});
const CreateCat = Loadable({
  loader: () => import('../pages/create-pet'),
  loading: LoadingComponent
});
const Home = Loadable({
  loader: () => import('../pages/home'),
  loading: LoadingComponent
});
const PanelInfo = Loadable({
  loader: () => import('../pages/panel-info'),
  loading: LoadingComponent
});
const MenuPage = Loadable({
  loader: () => import('../pages/menu-page'),
  loading: LoadingComponent
});
// const MinimalLayout = Loadable({
//   loader: () => import('../layout/MinimalLayout'),
//   loading: LoadingComponent
// });


const SynRouter = () => {

  return(
    <Routes>
        <Route  path="/login" element={<Login />} />
        
          <Route path="/" element={<Home />} />
          <Route path="/summary/:id" element={<MenuPage />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="/animal-info/:id" element={<AnimalInfo />} />
          <Route path="/add" element={<CreateCat />} />
          <Route path="/edit/:id" element={<CreateCat />} />
          <Route path="/panel-info" element={<PanelInfo />} />
        
    </Routes>
  )
}

export default SynRouter;
