import React from "react";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient,createHttpLink, InMemoryCache } from '@apollo/client'
import Auth from './auth/auth'
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const user = JSON.parse(localStorage.getItem('user'));;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: user ? `Bearer ${user.access_token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const App = () => {
  return(
    <ApolloProvider client={client}>
      <Auth />
    </ApolloProvider>
  )
}

export default App;