import React, {createContext} from 'react';
const userContext = createContext();

const SynUserContextProvider = (props) => {
  return (
    <userContext.Provider
      value={props.value}
    >
      {props.children}
    </userContext.Provider>
  )
}

export {SynUserContextProvider};

export default userContext;


